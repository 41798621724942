<template>
  <div>
    <!--   Farms   -->

    <card-component
      title="Farms"
      icon="finance"
      hide-content
    />

    <tiles>
      <card-widget
        class="tile is-child"
        type="is-info"
        icon="chart-bubble"
        :number="metrics.farms.value"
        label="Count of Farms"
      >
        <b-loading :is-full-page="false" v-model="metrics.farms.loading"></b-loading>
      </card-widget>
      <card-widget
        class="tile is-child"
        type="is-info"
        icon="all-inclusive"
        :number="metrics.ponds.value"
        label="Count of Ponds"
      >
        <b-loading :is-full-page="false" v-model="metrics.ponds.loading"></b-loading>
      </card-widget>
      <!--
            <card-widget
              class="tile is-child"
              type="is-info"
              icon="sync"
              :number="0"
              label="Count of Free Trial Ponds"
            />
            <card-widget
              class="tile is-child"
              type="is-info"
              icon="transit-connection-variant"
              :number="0"
              label="Count of Subscribed Ponds"
            />
            -->
    </tiles>

    <!--   Shrimp   -->

    <card-component
      title="Shrimp"
      icon="finance"
      hide-content
    />

    <tiles>
      <card-widget
        class="tile is-child"
        type="is-success"
        icon="chart-donut"
        :number="metrics.ponds_in_stock.value"
        label="Count of Ponds in Stock"
      >
        <b-loading :is-full-page="false" v-model="metrics.ponds_in_stock.loading"></b-loading>
      </card-widget>
      <card-widget
        class="tile is-child"
        type="is-success"
        icon="chart-donut-variant"
        :number="metrics.actual_shrimp_stock.value"
        label="Shrimp from ponds in Stock (actual)"
      >
        <b-loading :is-full-page="false" v-model="metrics.actual_shrimp_stock.loading"></b-loading>
      </card-widget>
    </tiles>

    <card-component>
      <div class="is-flex">
        <b-select v-model="type" @input="getData" class="mr-2">
          <option value="">All</option>
          <option value="month">Monthly</option>
          <option value="year">Yearly</option>
          <!--            <option :value="year" v-for="year in years" :key="year">{{ year }}</option>-->
        </b-select>

        <b-datepicker type="month"
                      placeholder="Select Month"
                      v-model="monthlyDate"
                      locale="id-ID"
                      v-if="type === 'month'"
                      @input="getData">
        </b-datepicker>

        <b-select v-model="yearlyDate" @input="getData" v-if="type === 'year'">
          <option :value="year" v-for="year in years" :key="year">{{ year }}</option>
        </b-select>
      </div>
    </card-component>

    <!--   Harvest   -->

    <card-component
      title="Harvest"
      icon="finance"
      hide-content
    />

    <tiles>
      <card-widget
        class="tile is-child"
        type="is-success"
        icon="circle-slice-3"
        :number="metrics.shrimp_partial_harvest.value"
        suffix=" Kg"
        label="Shrimp in total partial harvest"
      >
        <b-loading :is-full-page="false" v-model="metrics.shrimp_partial_harvest.loading"></b-loading>
      </card-widget>
      <card-widget
        class="tile is-child"
        type="is-success"
        icon="circle-slice-8"
        :number="metrics.shrimp_total_harvest.value"
        suffix=" Kg"
        label="Shrimp in total harvest"
      >
        <b-loading :is-full-page="false" v-model="metrics.shrimp_total_harvest.loading"></b-loading>
      </card-widget>
    </tiles>

    <shrimp-stock-map class="mb-5" v-if="!refresh" :from="from" :to="to"/>

    <!--   Disease   -->

    <card-component
      title="Disease"
      icon="finance"
      hide-content
    />

    <shrimp-disease-map class="mb-5" v-if="!refresh" :from="from" :to="to"/>
    <shrimp-death-map class="mb-5" v-if="!refresh" :from="from" :to="to"/>

  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import Tiles from "@/components/Tiles";
import CardWidget from "@/components/CardWidget";
import {mapActions, mapState} from "vuex";
import ShrimpStockMap from "@/components/Book/ShrimpStockMap";
import ShrimpDiseaseMap from "@/components/Book/ShrimpDiseaseMap";
import ShrimpDeathMap from "@/components/Book/ShrimpDeathMap";

export default {
  name: "BookMetrics",
  components: {ShrimpDeathMap, ShrimpDiseaseMap, ShrimpStockMap, CardWidget, Tiles, CardComponent},
  data: () => {
    return {
      type: '',
      monthlyDate: new Date(),
      yearlyDate: new Date().getFullYear(),
      from: null,
      to: null,
      refresh: false,
    }
  },
  computed: {
    ...mapState('farm', [
      'metrics',
    ]),

    years() {
      let current = 2020
      let res = []
      let currentYear = new Date().getFullYear()

      do {
        res.push(current)
        current++
      } while (current <= currentYear)

      return res
    },
  },
  created() {
    this.countFarms()
    this.countPonds()
    this.countPondsInStock()
    this.countActualShrimpInStock()
    this.getData()
  },
  methods: {
    ...mapActions('farm', [
      'countFarms',
      'countPonds',
      'countPondsInStock',
      'countActualShrimpInStock',
      'countShrimpInPartialHarvest',
      'countShrimpInTotalHarvest',
    ]),

    getData() {
      this.from = null
      this.to = null

      if (this.type === 'month') {
        this.from = this.$timestamp(this.$moment(this.monthlyDate).startOf('month'))
        this.to = this.$timestamp(this.$moment(this.monthlyDate).endOf('month'))
      } else if (this.type === 'year') {
        let date = this.$moment().set({year: this.yearlyDate})
        this.from = this.$timestamp(date.startOf('year'))
        this.to = this.$timestamp(date.endOf('year'))
      }

      this.refresh = true

      this.$nextTick(() => {
        this.refresh = false
      })

      this.getTotalHarvest()
      this.getPartialHarvest()
    },

    getPartialHarvest() {
      let payload = {}
      if (this.from) payload.from = this.from
      if (this.to) payload.to = this.to
      this.countShrimpInPartialHarvest(payload)
    },
    getTotalHarvest() {
      let payload = {}
      if (this.from) payload.from = this.from
      if (this.to) payload.to = this.to
      this.countShrimpInTotalHarvest(payload)
    }
  },
}
</script>
