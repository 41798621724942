<template>
  <div>
    <card-component title="Heatmap sebaran benur yang dipakai">
      <div class="columns mb-0">
        <div class="column is-8">
          <gmap-map
            ref="mapRef"
            :zoom="4.55"
            :center="center"
            map-type-id="roadmap"
            style="width: 100%; height: 500px;"
          >
            <gmap-marker
              v-for="(m, index) in markers"
              :key="index"
              :position="m.location"
              :clickable="true"
              :draggable="false"
              @click="showDetail(m)"
            />
            <gmap-heatmap-layer
              :data="markers"
              :options="{maxIntensity: maxIntensity, dissipating: false, radius: 2}"
            />
          </gmap-map>
        </div>

        <div class="column is-4">
          <div style="height: 500px; overflow-y: auto">
            <div class="is-flex is-justify-content-space-between" v-for="(item ,key ) in grouped_location"
                 :key="key">
              {{ item.name }}
              <span class="has-text-weight-bold">{{ $numberFormat(item.total) }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="map-info" v-if="detail">
        <div class="card">
          <div class="card-content px-3 py-2">
            <div>
              <div class="is-flex is-justify-content-space-between is-align-items-center mb-2">
                <h4 class="has-text-weight-bold mb-0">{{ detail.data.name }}</h4>
                <div class="cursor-pointer" @click="detail = null">
                  <b-icon class="has-text-grey" icon="close"/>
                </div>
              </div>
              <div class="columns is-gapless mb-2">
                <div class="column">Total</div>
                <div class="column">:
                  <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                        @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ detail.data.total }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card-component>
  </div>
</template>

<script>
/* eslint-disable */
import {mapActions, mapState} from "vuex";
import CardComponent from "@/components/CardComponent";

export default {
  name: "ShrimpStockMap",
  components: {CardComponent},
  props: {
    from: Number,
    to: Number,
  },
  data: () => {
    return {
      center: {lat: -2.5489, lng: 118.0149},
      markers: [],
      maxIntensity: 120,
      detail: null,
    }
  },
  computed: {
    ...mapState('farm', [
      'shrimp_stock_location',
    ]),
    grouped_location() {
      return this.shrimp_stock_location.data.reduce((a, b) => {
        let name = b.name.replace(',', '.').toLowerCase().trim()

        if (name === 'ayen') name = 'ajen'

        let find = a.find(e => e.name.toLowerCase().trim() === name)
        if (find) find.total += b.total
        else a.push(b)
        return a
      }, [])
    },
  },
  async created() {
    let payload = {}
    if (this.from) payload.from = this.from
    if (this.to) payload.to = this.to
    await this.getShrimpStockLocation(payload)
    await this.$gmapApiPromiseLazy();
    this.markers = []

    this.maxIntensity = 0

    for (let i = 0; i < this.shrimp_stock_location.data.length; i++) {
      let data = this.shrimp_stock_location.data[i]
      let location = data.location.split(',')
      this.markers.push({
        location: new google.maps.LatLng({lat: parseFloat(location[0]), lng: parseFloat(location[1])}),
        weight: data.total,
        data: data
      })
      if (data.total > this.maxIntensity) this.maxIntensity = data.total
    }
  },
  methods: {
    ...mapActions('farm', [
      'getShrimpStockLocation',
    ]),

    showDetail(m) {
      this.center = m.location
      this.detail = m
    },

    clickToCopy(e) {
      const el = document.createElement('textarea');
      el.value = e.target.innerText;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.$swal({
        icon: 'success',
        title: 'Copied to clipboard',
      })
    },
  }
}
</script>
